@import "https://fonts.googleapis.com/css2?family=Marcellus&family=Open+Sans:wght@300..700&display=swap";
*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden]:where(:not([hidden="until-found"])) {
  display: none;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-50 {
  z-index: 50;
}

.m-auto {
  margin: auto;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.-ml-4 {
  margin-left: -1rem;
}

.-mr-4 {
  margin-right: -1rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-8 {
  margin-top: 2rem;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.hidden {
  display: none;
}

.h-0\.5 {
  height: .125rem;
}

.h-12 {
  height: 3rem;
}

.h-2\.5 {
  height: .625rem;
}

.h-6 {
  height: 1.5rem;
}

.h-\[860px\] {
  height: 860px;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.w-12 {
  width: 3rem;
}

.w-2\.5 {
  width: .625rem;
}

.w-6 {
  width: 1.5rem;
}

.w-96 {
  width: 24rem;
}

.w-fit {
  width: fit-content;
}

.w-full {
  width: 100%;
}

.flex-1 {
  flex: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-16 {
  gap: 4rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-8 {
  gap: 2rem;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-4 {
  border-bottom-width: 4px;
}

.border-t {
  border-top-width: 1px;
}

.border-t-4 {
  border-top-width: 4px;
}

.border-dark {
  --tw-border-opacity: 1;
  border-color: rgb(248 236 245 / var(--tw-border-opacity, 1));
}

.border-secondary {
  --tw-border-opacity: 1;
  border-color: rgb(169 107 156 / var(--tw-border-opacity, 1));
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
}

.bg-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(248 236 245 / var(--tw-bg-opacity, 1));
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(96 12 72 / var(--tw-bg-opacity, 1));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.object-cover {
  object-fit: cover;
}

.p-4 {
  padding: 1rem;
}

.p-8 {
  padding: 2rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-32 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pt-0 {
  padding-top: 0;
}

.text-center {
  text-align: center;
}

.font-title {
  font-family: Marcellus, serif;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.leading-none {
  line-height: 1;
}

.tracking-wide {
  letter-spacing: .025em;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(26 27 31 / var(--tw-text-opacity, 1));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}

.text-grey {
  --tw-text-opacity: 1;
  color: rgb(136 130 128 / var(--tw-text-opacity, 1));
}

.text-primary {
  --tw-text-opacity: 1;
  color: rgb(96 12 72 / var(--tw-text-opacity, 1));
}

.text-secondary {
  --tw-text-opacity: 1;
  color: rgb(169 107 156 / var(--tw-text-opacity, 1));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.opacity-100 {
  opacity: 1;
}

.opacity-5 {
  opacity: .05;
}

.ring-0 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-white {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity, 1));
}

.grayscale {
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-300 {
  transition-duration: .3s;
}

:root {
  --primary-color: #b8967e;
  --dark-color: #0a1d35;
  --secondary-color: #878c97;
  --grey-color: #faf6f3;
  --white-color: #fff;
  --title-fonts: "Marcellus", sans-serif;
  --body-fonts: "Open Sans", sans-serif;
}

body {
  touch-action: pan-y;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--title-fonts);
}

.hover\:bg-primary\/70:hover {
  background-color: #600c48b3;
}

.hover\:text-primary:hover {
  --tw-text-opacity: 1;
  color: rgb(96 12 72 / var(--tw-text-opacity, 1));
}

@media (width >= 640px) {
  .sm\:mb-0 {
    margin-bottom: 0;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:pe-8 {
    padding-inline-end: 2rem;
  }

  .sm\:ring-8 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }
}

@media (width >= 1024px) {
  .lg\:-right-\[200px\] {
    right: -200px;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:h-auto {
    height: auto;
  }

  .lg\:w-\[400px\] {
    width: 400px;
  }

  .lg\:w-\[800px\] {
    width: 800px;
  }

  .lg\:min-w-\[400px\] {
    min-width: 400px;
  }

  .lg\:max-w-\[800px\] {
    max-width: 800px;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:gap-16 {
    gap: 4rem;
  }

  .lg\:gap-32 {
    gap: 8rem;
  }

  @media (width >= 1024px) {
    .lg\:lg\:px-96 {
      padding-left: 24rem;
      padding-right: 24rem;
    }
  }

  .lg\:px-96 {
    padding-left: 24rem;
    padding-right: 24rem;
  }

  .lg\:text-left {
    text-align: left;
  }

  .lg\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }
}

@media (width >= 1280px) {
  .xl\:px-96 {
    padding-left: 24rem;
    padding-right: 24rem;
  }
}

@media (width >= 1536px) {
  .\32 xl\:px-96 {
    padding-left: 24rem;
    padding-right: 24rem;
  }
}

/*# sourceMappingURL=index.5f55388e.css.map */
