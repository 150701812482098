@import url("https://fonts.googleapis.com/css2?family=Marcellus&family=Open+Sans:wght@300..700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

/* add your global styles here; use tailwind classes for component/element styles */

:root {
  --primary-color: #b8967e;
  --dark-color: #0a1d35;
  --secondary-color: #878c97;
  --grey-color: #faf6f3;
  --white-color: #ffffff;
  --title-fonts: "Marcellus", sans-serif;
  --body-fonts: "Open Sans", sans-serif;
}

body {
  overflow-x: hidden;
  touch-action: pan-y;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--title-fonts);
}
